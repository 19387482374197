import React from 'react'

const LogoPlaceholder = () => {
  return (
    <div className="flex gap-x-2 items-center place-content-center">
      <div className="rounded-full w-8 h-8 bg-indigo-500"></div>
      <p className="text-4xl font-black text-indigo-500 tracking-tight antialiased">peer</p>
    </div>
  )
}

export default LogoPlaceholder