import React from 'react'
import { Outlet } from 'react-router-dom'
import LogoPlaceholder from './LogoPlaceholder'

const LandingNavbar = () => {
    return (
        <>
            <div className='h-16 w-full absolute top-0 left-0 right-0 md:px-32 px-16 py-4 flex items-center justify-between md:px-32 px-16'>
                <LogoPlaceholder></LogoPlaceholder>
            </div>
            <Outlet></Outlet>
        </>
    )
}

export default LandingNavbar