import React from 'react';

const Landing = () => {
    return (
        <div className='md:px-32 px-16 pb-16 flex flex-col gap-y-8'>
            <div className='md:pt-32 pt-24 flex flex-col gap-y-8 items-start md:items-center'>
                <div className='flex flex-col gap-y-4 text-left md:text-center'>
                    <span className='lg:text-5xl md:text-3xl text-2xl font-semibold text-gray-800'>
                        Find amazing <span className='font-black text-indigo-500 w-64'>people</span>
                        <br />
                        <span>and bring your ideas to life</span>
                    </span>

                    <p className='text-gray-600 text-left md:text-center'>
                        Whether you're a student, researcher, industry professional - or all of the above - <span className='font-semibold'>peer</span> helps you find your people. <span className='font-black'>Coming soon.</span>
                    </p>
                </div>

                <div className="flex flex-col items-center">
                    <iframe
                        title="Coming soon"
                        src="https://giphy.com/embed/Pm4IA0XrTWwE2DaTMI"
                        width="240"
                        height="240"
                        className="border-none rounded-lg"
                        allowFullScreen
                    ></iframe>
                    <p className="mt-2 text-sm text-gray-500">
                        <a
                            href="https://giphy.com/gifs/good-job-build-maja-sfstrm-Pm4IA0XrTWwE2DaTMI"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                        >
                            via GIPHY
                        </a>
                    </p>
                </div>
            </div>


        </div >
    )
}

export default Landing